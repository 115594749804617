(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbNavigation.directive:wbMainMenu
   * @restrict E
   * @element
   *
   * @description Displays the header navigation of the application
   *
   * @example
   <example module="wbNavigation">
   <file name="index.html">
   <wb-main-menu></wb-main-menu>
   </file>
   </example>
   *
   */
  angular
    .module('wbNavigation')
    .directive('wbMainMenu', wbMainMenu);

  function wbMainMenu() {
    return {
      restrict: 'E',
      templateUrl: 'wb-navigation/directives/wb-main-menu-directive.tpl.html',
      replace: true,
      controllerAs: 'wbMainMenu',
      controller: function (LoggedUser, OrderService, $translate, $window, SessionService, moment) {
        var vm = this;

        /**
         * Check if the current user is authenticated and has a selected company
         *
         * @returns {boolean}
         */
        vm.hasLoggedInElements = function hasLoggedInElements() {
          return LoggedUser.getCompany() && LoggedUser.isAuthenticated();
        };

        /**
         * Get fullname of the user and have binding with the layout
         *
         * @returns {string} fullname of the current user based on authentication
         */
        vm.getFullNameOfCurrentUser = function getFullNameOfCurrentUser() {
          var user;

          if (!vm.hasLoggedInElements()) {
            return '';
          }

          user = LoggedUser.getUser();

          return user.lastName + ' ' + user.firstName;
        };

        /**
         * @see OrderService.cartHasNewCardOrder
         * @returns {boolean} if cart has newCard order
         */
        vm.cartHasNewCardOrder = function cartHasNewCardOrder() {
          return OrderService.cartHasNewCardOrder();
        };

        /**
         * @see OrderService.cartHasTopUpOrder
         * @returns {boolean} if cart has TopUp order
         */
        vm.cartHasTopUpOrder = function cartHasTopUpOrder() {
          return OrderService.cartHasTopUpOrder();
        };

        /**
         * Changes the application language and reloads it
         *
         * @param {string} language Two character country code
         */
        vm.changeLanguageTo = function changeLanguageTo(language) {
          $translate.use(language);
          $window.location.reload();
        };

        /**
         * Check if the current language is the one passed as a parameter
         *
         * @param {string} language Two character country code
         * @returns {boolean} based on the parameter
         */
        vm.isCurrentLanguage = function isCurrentLanguage(language) {
          return $translate.use() === language;
        };

        /**
         * Return the logged company's vat number
         *
         * @returns {string}
         */
        vm.getActiveCompanyVatNumber = function getActiveCompanyVatNumber() {
          return LoggedUser.getCompany().vatNumber;
        };

        vm.showSessionTimer = function showSessionTimer() {
          var sessionTimer = SessionService.getSessionTimer();
          var minutes = moment.duration(sessionTimer).minutes();
          var seconds = moment.duration(sessionTimer).seconds();

          if (minutes === 0 && seconds === 0) {
            return false;
          }

          return sessionTimer && (minutes <= 9 && seconds <= 59);
        };

        vm.getSessionTimer = function getSessionTimer() {
          var sessionTimer = SessionService.getSessionTimer();
          var minutes = moment.duration(sessionTimer).minutes();
          var seconds = moment.duration(sessionTimer).seconds();

          function convertTwoDigits(value) {
            return String(value).length < 2 ? '0' + value : value;
          }

          return convertTwoDigits(minutes) + ':' + convertTwoDigits(seconds);
        };
      }
    };
  }
}());
